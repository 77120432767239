<template>
    <div>
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>受检管理</el-breadcrumb-item>
          <el-breadcrumb-item>受检评估</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="routerBox">
        <!-- 顶部tabs -->
        <el-tabs v-model="tabsValue" type="card" @tab-click="setTabs">
            <el-tab-pane v-for="(item) in editableTabs" :key="item.name" :label="item.title" :name="item.name"></el-tab-pane>
        </el-tabs>
        <!-- 三个子路由  脊柱初筛  专业脊柱评估  x光检测  -->
        <div style="width:100%;">
            <router-view></router-view>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
       
    },
    data(){
        return{
            tabsValue:'0',
            tabIndex: 1,
            editableTabs: [{
            title: '脊柱初筛',
            name: '0',
            content: '脊柱初筛'
            }, {
            title: '专业脊柱评估',
            name: '1',
            content: '专业脊柱评估'
            },
            {
            title: 'Cobb角计算',
            name: '2',
            content: 'Cobb角计算'
            }],
        }

    },
    
    mounted(){
        var router = this.$route.name;
        if(router === 'Chaffer'){
         this.tabIndex = 0;
         this.tabsValue = '0';
        }
        if(router === 'Major'){
            this.tabIndex = 1;
            this.tabsValue = '1';
        }
        if(router === 'Cobb'){
            this.tabIndex = 2;
            this.tabsValue = '2';
        }
    },
    watch:{
      '$route':function(){
        if(this.$route.name === 'Chaffer'){
         this.tabIndex = 0;
         this.tabsValue = '0';
        }; 
        if(this.$route.name === 'Major'){
            this.tabIndex = 1;
            this.tabsValue = '1';
        };
        if(this.$route.name === 'Cobb'){
            this.tabIndex = 2;
            this.tabsValue = '2';
        }
      }
    },
    methods:{
        setTabs(e){
            var label = e.label
            switch (label) {
                case '脊柱初筛':
                this.$router.push({path:'/spineEvaluation/chaffer'});
                this.tabsValue = '0';
                this.tabIndex = 0;
                    break;

                case '专业脊柱评估':
                this.$router.push({path:'/spineEvaluation/major'});
                this.tabsValue = '1';
                this.tabIndex = 1;
                    break;

                case 'Cobb角计算':
                this.tabsValue = '2';
                this.tabIndex = 2;
                this.$router.push({path:'/spineEvaluation/cobb'})
                    break;
            
                default:
                    console.log('other')
                    break;
            }
        }
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }   
.routerBox
  width: 100%;
  margin-top: .16rem;
</style>